<template src="./DetailNews.html"></template>

<script>
import newsService from "../../services/News";
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";
import likeCommentService from "../../services/LikeAndComment";
import notificationsService from "../../services/Notifications";

export default {
  name: "detailNews",
  components: {
    ChosenUniversity,
  },
  data() {
    return {
      news: { institution: { name: "" } },
      modalDeleteNews: false,
      role: "",
      institutionId: "",
      newComment: "",
      likeHeart: { like: false, id: "" },
      comments: [],
      modalShareNews: false,
      userAuthor: false,
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.name;
    this.role = this.$store.state.User.user.role.name;
    this.getSpecificNews();
  },
  methods: {
    async getSpecificNews() {
      try {
        const infoCommentsByPost = {
          typePost: "articlesBlog",
          postId: this.$route.params.id.toString(),
        };
        const objLike = {
          typePost: "articlesBlog",
          postId: this.$route.params.id.toString(),
        };
        this.news = await newsService.getSpecificNews(this.$route.params.id);
        if (this.$store.state.User.user.id === this.news.author.id) {
          this.userAuthor = true;
        }
        this.comments = await likeCommentService.getAnnouncementComments(
          infoCommentsByPost
        );
        this.likeHeart = await likeCommentService.likeByPost(objLike);
      } catch (e) {
        console.error("error", e);
      }
    },
    deleteNews() {
      try {
        this.modalDeleteNews = true;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/deleteNews()", e);
      }
    },
    async removeNews() {
      try {
        await newsService.deleteNews(this.$route.params.id);
        this.modalDeleteNews = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Noticia eliminada",
          position: "is-bottom",
          type: "is-success",
        });
        await this.$router.push({ name: "ViewAllNews" });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/removeNews()", e);
      }
    },
    async sendComments() {
      if (this.newComment.length === 0) {
        return null;
      }
      try {
        const infoComments = {
          post: this.$route.params.id.toString(),
          typePost: "articlesBlog",
          commentText: this.newComment,
        };
        const responseComment = await likeCommentService.createComment(
          infoComments
        );
        this.newComment = "";
        this.comments.push(responseComment);
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    showModalShareNews() {
      this.modalShareNews = true;
    },
    async share() {
      try {
        const infoShare = {
          title: "Te han compartido una noticia",
          body: this.news.name,
          // TO DO servicio mal estructurado no deberia enviarse el id de la notificacion quemado
          typeId: "6ee66b47-641d-4d24-ad90-5e4264d27069",
        };
        await notificationsService.shareAnnouncement(infoShare);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Noticia compartida",
          position: "is-bottom",
          type: "is-success",
        });
        this.modalShareNews = false;
      } catch (error) {
        console.error(error);
      }
    },
    async addLike() {
      try {
        if (this.likeHeart.like) {
          const objLikeCreate = {
            post: this.$route.params.id.toString(),
            typePost: "articlesBlog",
            like: this.likeHeart.like,
          };
          const objLike = {
            typePost: "articlesBlog",
            postId: this.$route.params.id.toString(),
          };
          await likeCommentService.createLike(objLikeCreate);
          this.likeHeart = await likeCommentService.likeByPost(objLike);
        } else {
          await likeCommentService.deleteLike(this.likeHeart.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidation() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    roleValidationTeacherInvestigator() {
      return ["TeacherInvestigator"].some((a) => a === this.role);
    },
    typeOfHeart() {
      return (like) => {
        switch (like) {
          case false:
            return "fa-regular fa-heart";
          case true:
            return "fa-solid fa-heart";
          default:
            null;
            return "fa-regular fa-heart";
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./DetailNews.scss">

</style>